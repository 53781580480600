<template>
    <footer>
        <div>
            <a href="https://www.instagram.com/solutio_software/" target="_blanc"><img src="@/assets/Vector.png" alt=""></a>
            <a href="https://www.linkedin.com/company/solutiosoftware/" target="_blanc"><img src="@/assets/Vector-Linkedin.png" alt=""></a>
            <span> Entre em contato: (51) 4042-7733</span>
        </div>
        <div>
            <a @click="$router.push('/quemSomos')">Quem somos</a>
            <a href="https://www-solutiosoftware-com-br-1.rds.land/so-wil">Segurança</a>
            <a href="https://api.whatsapp.com/send/?phone=555140427733&text&type=phone_number&app_absent=0" target="_blank">Solicitar proposta</a>
            <a @click="$router.push('/termoDeUso')">Termos de Uso</a>
            <a @click="$router.push('/politicaDePrivacidade')">Politica de Privacidade</a>
        </div>
    </footer>
</template>

<script>

</script>

<style scoped>
    footer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    footer div{
        flex-direction: row;
        padding: 10px;
    }
    a{
        text-decoration: none;
        cursor: pointer;
        margin: 10px;
    }
    @media (max-width: 600px){
        a{
            margin: 5px;
        }
        footer div{
            padding: 10px 0;
        }
    }
</style>