<template>
  <BaseHeader />
  <router-view/>
  <br>
  <BaseFooter />
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css">
  <a href="https://wa.me/555197694392" id="whatsappButton" target="_blank">
  <i style="margin-top:16px" class="fa fa-whatsapp"></i>
  </a>
</template>

<script>
import BaseHeader from '@/components/BaseHeader.vue';
import BaseFooter from '@/components/BaseFooter.vue'
export default{
  components: { BaseHeader, BaseFooter },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#whatsappButton{
  position:fixed;
  width:60px;
  height:60px;
  bottom:40px;
  right:40px;
  background-color:#003F75;
  color:#FFF;
  border-radius:50px;
  text-align:center;
  font-size:30px;
  box-shadow: 1px 1px 2px #888;
  z-index:1000;
}
a, span, p, h1, h2, h3{
  color: #003F75;
}
.button{
  background-color: #003F75;
  color: #FFF;
  border-radius: 0.2em;
  box-shadow: 0 6px 10px -2px lightgray;
  border: 2px solid #003F75;
  padding: 10px 20px 10px 20px;
  cursor:pointer;
}
.button:hover{
  color: #003F75;
  background-color: #fff;
  border: 2px solid #003F75;
}
.card{
  background-color: #FFF;
  border-radius: 0.2em;
  box-shadow: 0 6px 10px -2px lightgray;
  margin: 15px 0 15px 0;
}
.icon{
  background: linear-gradient(89.19deg, #003F75 0.75%, #0067BF 98.15%);
  border-radius: 8px;
}
.content{
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
@media (max-width: 600px)
{
  #app{
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 5%;
    margin:3%;
  }
  .button{
    background-color: #003F75;
    color: #FFF;
    border-radius: 0.2em;
    box-shadow: 0 6px 10px -2px lightgray;
    padding: 10px 20px 10px 20px;
  }
  #whatsappButton{
    right: 10px;
    bottom: 20px;
  }
}
</style>
