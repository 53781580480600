<template>
    <div class="header">
        <div class="headerNav">
            <a @click="$router.push('/')" class="homeButton"><img src="@/assets/logo-SOLUTIO.png" alt=""></a>
            <div class="links">
                <div class="navButtons">
                    <a @click="$router.push('/quemSomos')"><strong>Quem somos</strong></a>
                    <a href="https://materiais.solutiosoftware.com.br/so-wil"><strong>Segurança Eletrônica</strong></a> 
                    <a href="https://materiais.solutiosoftware.com.br/ronda-inteligente"><strong>Segurança Patrimonial</strong></a> 
                    <a href="https://solutio-software.movidesk.com/"><strong>Suporte</strong></a> 
                </div>
                <a href="https://api.whatsapp.com/send/?phone=555140427733&text&type=phone_number&app_absent=0" target="_blank" class="button requestOfferButton"><strong>Solicitar proposta</strong></a>
            </div>
        </div>
    </div>
</template>

<script>
    export default{
    }
</script>

<style scoped>
    a{
        padding: 10px;
        margin-right: 5px;
        text-decoration: none;
        cursor: pointer;
        text-align: start;
    }
    img{
        margin-left:10px;
        width: 100%;
        margin-bottom:0;
    }
    .header{
        padding: 10px 0;
        box-shadow: 0 6px 10px -2px lightgray;
        position:fixed;
        top: 0;
        right: 0;
        width: 100%;
        background-color: #FFF;
        z-index: 999;
    }
    .header div{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .navButtons{
        display: flex;
        align-items: center;
    }
    .homeButton{
        width:10%;
    }
    .links{
        display: flex;
        justify-content: flex-end;
    }

    @media (max-width: 600px)
    {
        img{
            width: 60%;
            margin-bottom: 5px;
            margin-left: -15px;
        }
        a{
            text-align: center;
        }
        .header{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-bottom: 0; 
        }
        .headerNav{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        .navButtons{
            display: flex;
            font-size: 14px;
            margin-bottom: 10px;
        }
        .button{
            padding:5px;
            display: flex;
            align-items: center;
        }
        .homeButton{
            width:100%;
        }

        .requestOfferButton{
            margin: 10px;
            margin-right: 30px;
            padding: 10px;
        }

        .requestOfferButton strong{
            width:100%;
        }

        .links{
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
        }
    }
</style>